<template>

<div v-if="href" :class="xl ? 'pt-4' : 'pt-2'">
  <a v-if="buttonType === 'primary'" :href="link" class="text-center min-w-max inline-block bg-redDamask rounded-full text-white font-body text-bodyL font-medium cursor-pointer hover:bg-redDamask-dark focus:bg-redDamask-dark active:bg-redDamask-dark" :class="[xl ? 'py-5 px-7' : 'px-5 py-2.5', fullWidth ? 'w-48' : '', customClass]">{{ text }}</a>
  <a v-else-if="buttonType === 'secondary'" :href="link" class="text-center min-w-max inline-block bg-redDamask bg-opacity-30 border border-redDamask rounded-full text-white font-body text-bodyL font-medium cursor-pointer hover:bg-opacity-50 focus:bg-opacity-50 active:bg-opacity-50" :class="[xl ? 'py-5 px-7' : 'px-5 py-2.5', fullWidth ? 'w-48' : '', customClass]">{{ text }}</a>
  <a v-else-if="buttonType === 'tertiary'" :href="link" class="text-center min-w-max inline-block bg-transparent border border-redDamask rounded-full text-redDamask font-body text-bodyL font-medium cursor-pointer" :class="[xl ? 'py-5 px-7' : 'px-5 py-2.5', fullWidth ? 'w-48' : '', customClass]">{{ text }}</a>
</div>
<div v-else :class="xl ? 'pt-2' : 'pt-px'">
  <button v-if="buttonType === 'primary'" :disabled="disabledBtn" :type="buttonFunction" class="text-center min-w-max inline-block bg-redDamask rounded-full text-white font-body text-bodyL font-medium cursor-pointer hover:bg-redDamask-dark focus:bg-redDamask-dark active:bg-redDamask-dark" :class="[xl ? 'py-5 px-7' : 'px-5 py-2.5', fullWidth ? 'w-48' : '', customClass]">{{ text }}</button>
  <button v-else-if="buttonType === 'secondary'" :disabled="disabledBtn" :type="buttonFunction" class="text-center min-w-max inline-block bg-redDamask bg-opacity-30 border border-redDamask rounded-full text-white font-body text-bodyL font-medium cursor-pointer hover:bg-opacity-50 focus:bg-opacity-50 active:bg-opacity-50" :class="[xl ? 'py-5 px-7' : 'px-5 py-2.5', fullWidth ? 'w-48' : '', customClass]">{{ text }}</button>
  <button v-else-if="buttonType === 'tertiary'" :disabled="disabledBtn" :type="buttonFunction" class="text-center min-w-max inline-block bg-transparent border border-redDamask rounded-full text-redDamask font-body text-bodyL font-medium cursor-pointer" :class="[xl ? 'py-5 px-7' : 'px-5 py-2.5', fullWidth ? 'w-48' : '', customClass]">{{ text }}</button>
  <button v-else-if="buttonType === 'game'" :disabled="disabledBtn" :type="buttonFunction" class="text-center min-w-max inline-block bg-twine border border-twine rounded-full text-halfColonialWhite font-body text-bodyS font-medium cursor-pointer" :class="[xl ? 'py-5 px-7' : 'pt-1 pb-0.5 px-2.5', fullWidth ? 'w-48' : '', customClass]">{{ text }}</button>
</div>

</template>

<script>
export default {
  name: 'button',
  props: {
    text: {
      type: String,
      required: true
    },
    buttonType: {
      type: String,
      required: true
    },
    buttonFunction: {
      type: String,
      required: false,
      default: 'submit'
    },
    href: {
      type: String,
      required: false
    },
    xl: {
      type: Boolean,
      required: false
    },
    fullWidth: {
      type: Boolean,
      required: false,
      default: false
    },
    disabledBtn: {
      type: Boolean,
      required: false,
      default: false
    },
    customClass: {
      type: String,
      required: false
    },
  },
  data () {
    return {
      link: this.href
    }
  },
}
</script>

<style>

</style>