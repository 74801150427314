<template>
	<a :href="'/' + device + '/' + entity_id +'/timeline'" class="flex items-center content-center bg-redDamask rounded-full text-white font-body text-bodyL font-medium cursor-pointer hover:bg-redDamask-dark focus:bg-redDamask-dark active:bg-redDamask-dark p-2 pr-4" 
		v-on:click="saveToTimeline()">
		<BaseIcon name="timelineWhite" outline = false />
		{{ $t('entity.saveBtn') }}
	</a>
</template>

<script>
import BaseIcon from "@/components/BaseIcon.vue";

export default {
	name: 'Save',
	components: {
		BaseIcon
	},
	mounted() {
		this.$i18n.locale = this.lang;
	},
	data(){
        return {
            baseURL: process.env.VUE_APP_AXIOS_URL,
			lang: localStorage.getItem('language') ? localStorage.getItem('language') : 'ro',
        }
    },
    props: ["entity_id", "device"],
    methods: {
      saveToTimeline: function() {
        // this.$router.push(window.location.pathname +'/timeline');
      }
    }
}
</script>
