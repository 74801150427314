<template>
  <div class="flex items-center content-center gap-2">
    <!-- <PrevNext /> -->
    <Save :entity_id="entity_id" :device="device" />
  </div>
</template>

<script>

// import PrevNext from "@/components/tablet/PrevNext.vue";
import Save from "@/components/tablet/Save.vue";

export default {
  name: 'Navigation',
  props: ["entity_id", "device"],
  components: {
    // PrevNext,
    Save
  }
}
</script>
